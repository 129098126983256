import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useIntl, injectIntl } from "gatsby-plugin-intl";

const NotFoundPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="404: Not found" />
      <h1>{intl.formatMessage({ id: 'NotFound' })}</h1>
    </Layout>
  )
}

export default injectIntl(NotFoundPage)
